<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--关联订单编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联订单编号"
                    label-for="relational_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="relational_no"
                      size="sm"
                      v-model="condition.relational_no"
                      placeholder="请输入关联订单编号"
                  />
                </b-form-group>
              </b-col>
              <!--公司名称-->
              <b-col md="3">
                <modal-select
                    label="公司名称"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name','company_id']"
                    :isAllState="true"
                    modalName="终端客户"
                    placeholder="点击选择公司名称"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--店铺名称-->
              <b-col md="3">
                <modal-select
                    label="店铺名称"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                    v-model="condition.store_name"
                >
                </modal-select>
              </b-col>
              <!--订单分类-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单分类"
                    label-for="order_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="order_type"
                      :options="getCodeOptions('credit_log_order_type')"
                      v-model="condition.order_type"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择订单分类"
                  />
                </b-form-group>
              </b-col>
              <!--额度类型-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="额度类型"
                    label-for="credit_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="credit_type"
                      :options="credit_type_options"
                      v-model="condition.credit_type"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择额度类型"
                  />
                </b-form-group>
              </b-col>
              <!--操作类型-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="操作类型"
                    label-for="op_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="op_type"
                      :options="getCodeOptions('credit_op_type')"
                      v-model="condition.op_type"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择操作类型"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!--额度类型-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--额度类型-->
        <template #cell(credit_type)="data">
          <b-badge pill variant="light-info">
            {{ data.item.credit_type === 1 ? "授信额度" : "临时额度"}}
          </b-badge>
        </template>

        <!--操作类型-->
        <template #cell(op_type)="data">
          <b-badge pill variant="light-info">
            {{ getCodeLabel('credit_op_type', data.item.op_type) }}
          </b-badge>
        </template>

        <!--关联订单分类-->
        <template #cell(order_type)="data">
          <b-badge pill variant="light-info">
            {{ getCodeLabel('credit_log_order_type', data.item.order_type) }}
          </b-badge>
        </template>

        <!--备注-->
        <template #cell(remark)="data">
          <div :id="`remark-${data.item.id}`">
            <span v-if="data.item.remark">
              {{ data.item.remark.substring(0, 6) }}
              <span v-if="data.item.remark.length>6">...</span>
            </span>
          </div>
          <b-tooltip :target="`remark-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!--时间-->
        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time) }}
        </template>

        <!--添加人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import companycreditlogUseList from './companycreditlogUseList'
import companycreditlogStore from './companycreditlogStore'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  data() {
    return {
      isShowCard: false,
      credit_type_options:[{label:"授信额度",value:1},{label:"临时额度",value:2}],
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule('companycreditlog')) store.registerModule('companycreditlog', companycreditlogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companycreditlog')) store.unregisterModule('companycreditlog')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = companycreditlogUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      fromChildren,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
